import {
  api,
  BossRush,
  OtherActivities,
  PlatinumFieldsField,
  LfgTag,
  PubgMobileRank,
  CodMobileRank,
  Standoff2Rank,
} from 'api';

import * as cod from 'const/games/cod';
import * as custom from 'const/games/custom';
import * as pubg from 'const/games/pubg';
import * as steam from 'const/games/steam';
import { ClientLanguage, getPreferredLanguageFromCookie } from 'utils/language';

import { useApp } from '../ContextApp';

import { Translations, translations } from './generated';

import type { SupportedGameId } from 'api/types';
import type { DocumentNode } from 'graphql';

export type Translation = Record<'english' | 'russian' | 'arabic', string>;
export type TranslationMap<T extends string> = { [key in T]: Translation };

export const mapLanguage = (
  language: ClientLanguage | undefined,
): keyof Translation => {
  switch (language) {
    case ClientLanguage.Russian:
      return 'russian';
    case ClientLanguage.Arabic:
      return 'arabic';
    default:
      return 'english';
  }
};

export function useTextNew<K>(
  selector?: (fullState: Translations) => K,
): [K, ClientLanguage] {
  const app = useApp();
  if (!app.language) {
    throw new Error('Rendering before language is loaded');
  }

  const lang = mapLanguage(app.language);

  // console.log('text', app.language, lang);
  if (selector) {
    return [selector(translations[lang] as Translations), app.language];
  }

  return [translations[lang] as unknown as K, app.language];
}

export function useText<K>(selector?: (fullState: Translations) => K): K {
  const app = useApp();
  if (!app.language) {
    throw new Error('Rendering before language is loaded');
  }

  const lang = mapLanguage(app.language);

  // console.log('text', app.language, lang);
  if (selector) {
    return selector(translations[lang] as Translations);
  }

  return translations[lang] as unknown as K;
}

/** @deprecated */
export function getLocaleObject(
  language?: ClientLanguage,
): (typeof translations)['english'] {
  const cookieLang = getPreferredLanguageFromCookie();
  const lang = mapLanguage(language || cookieLang);

  return translations[lang];
}

export function useTranslations() {
  const app = useApp();

  return getTranslation(app.language);
}

export type DynamicTranslations = ReturnType<typeof getTranslation>;

/** @deprecated */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getTranslation(lang?: ClientLanguage) {
  const locale = getLocaleObject(lang);

  return {
    error: {
      default: (node: DocumentNode) => {
        const text = locale.errors.default;

        const method = (node.definitions[0] as any)?.selectionSet?.selections[0]?.name
          .value as string;

        return ((text as Record<string, unknown>)[method] as any)?.title;
      },
      code: (code: api.ErrorCode) => {
        const text = locale.errors.codes;

        const data: Record<api.ErrorCode, string> = {
          AlreadyExists: 'Already exists',
          AppRequired: 'App required',
          UserPrivate: 'User is private',
          EventJoinSlotHasUser: text.GroupJoinSlotHasUser,
          EventJoinInvalidWowFaction: text.GroupJoinInvalidWowFaction,
          EventJoinInvalidRegion: text.GroupJoinInvalidRegion,
          EventJoinNoSocial: text.GroupJoinNoSocial,
          AuthRequired: text.AuthRequired,
          InvalidUsername: text.InvalidUsername,
          EventDeleted: text.GroupDeleted,
          EventFull: text.GroupFull,
          EventRoleFull: text.GroupRoleFull(''),
          EventNameEmpty: text.GroupNameEmpty,
          EventOwnerSlotRequried: text.GroupOwnerSlotRequried,
          EventNameTooLong: text.GroupNameTooLong,
          EventSlotsLimitExceeded: text.GroupSlotsLimitExceeded,
          EventNotOwner: text.GroupNotOwner,
          DeleteSocialAccountForbidden: text.DeleteSocialAccountForbidden,
          NotFound: text.NotFound,
          PermissionDenied: text.PermissionDenied,
          ValidationError: text.ValidationError,
          RuntimeError: text.RuntimeError,
          RiotNotFound: text.RiotNotFound,
          ThirdPartyTimeout: text.ThirdPartyTimeout,
          EventJoinInvalidRole: text.GroupJoinInvalidRole,
          LimitExceeded: 'Limit exceeded',
          EventJoinLostArkLowGs: text.GroupJoinLostArkLowGs,
          EventScheduledInPast: text.GroupScheduledInPast,
          UserBanned: 'Banned',
          AliasAlreadyExists: text.AliasAlreadyExists,
          EventNotFollowing: 'Not following',
          EventMerchantAlreadyExists: '',
          EventMerchantInvalidTime: '',
        };

        return data[code];
      },
    },
    filters: {
      language: (lang: api.Maybe<api.Language>) => {
        const text = locale.home.filters.language;

        if (!lang) {
          return text.placeholder;
        }

        const data: Record<api.Language, string> = {
          En: text.english,
          Ru: text.russian,
          De: text.german,
          Es: text.spanish,
          Fr: text.french,
          Pt: text.portuguese,
          It: text.italian,
          Nl: text.dutch,
          Pl: text.polish,
          Tr: text.turkish,
          Se: text.swedish,
        };

        return data[lang];
      },
    },
    eventType: (type: api.Maybe<api.EventType>) => {
      const text = locale.gameData.event;

      if (!type) {
        return text.anyTitle;
      }

      const data: Record<api.EventType, string> = {
        Event: text.teamPlay,
        Waitlist: text.Waitlist,
        Public: text.Public,
        Giveaway: text.Giveaway,
        Link: text.Link,
        Post: text.Post,
      };

      return data[type];
    },
    eventTypePlural: (type: api.Maybe<api.EventType>) => {
      const text = locale.gameData.event;

      if (!type) {
        return text.anyTitle;
      }

      const data: Record<api.EventType, string> = {
        Event: text.teamPlay,
        Waitlist: text.Waitlist,
        Public: text.Public,
        Giveaway: text.Giveaways,
        Link: text.Links,
        Post: text.Post,
      };

      return data[type];
    },
    gameMode: (mode: api.Maybe<api.GameMode>) => {
      const text = locale.gameData.gameMode;
      const textTitle = locale.gameData.gameModeTitle;

      interface GameMode {
        usual: string;
        title: string;
        short?: string;
        metaTitle?: string;
      }

      if (!mode) {
        const result: GameMode = {
          usual: text.anyTitle,
          title: text.anyTitle,
        };

        return result;
      }

      const data: Record<api.GameMode, GameMode> = {
        CSGO2v2: {
          usual: text.CSGO2v2,
          title: textTitle.CSGO2v2,
          metaTitle: 'CS:GO Team Finder - Counter Strike Player Finder | LF.Group',
        },
        CSGO5v5: {
          usual: text.CSGO5v5,
          title: textTitle.CSGO5v5,
          metaTitle: 'CS:GO Team Finder - Counter Strike Player Finder | LF.Group',
        },
        WowArena2v2: {
          usual: text.WowArena2v2,
          title: textTitle.WowArena2v2,
          metaTitle: 'WoW Arena LFG - Arena Partner Finder | LF.Group',
        },
        WowArena3v3: {
          usual: text.WowArena3v3,
          title: textTitle.WowArena3v3,
          metaTitle: 'WoW Arena LFG - Arena Partner Finder | LF.Group',
        },
        WowMythicPlus: {
          usual: text.WowMythicPlus,
          title: textTitle.WowMythicPlus,
          metaTitle: 'WoW Mythic Plus LFG - Mythic Dungeons Group Finder | LF.Group',
          short: 'M+',
        },
        WowRaid: {
          usual: text.WowRaid,
          title: textTitle.WowRaid,
          metaTitle:
            'WoW Raids LFG - World of Warcraft Raids & Dungeons Group Finder | LF.Group',
          short: 'PvE',
        },
        WowRBG: {
          usual: text.WowRBG,
          title: textTitle.WowRBG,
          metaTitle: 'WoW RBG LFG - Rated Battlegrounds Group Finder | LF.Group',
        },
        WowCustom: {
          usual: text.WowCustom,
          title: textTitle.WowCustom,
          metaTitle:
            'WoW LFG (Group Finder) - Looking for Group in World of Warcraft | LF.Group',
        },
        HearthstoneBattlegrounds: {
          usual: text.HearthstoneBattlegrounds,
          title: textTitle.HearthstoneBattlegrounds,
        },
        HearthstoneBGLeague: {
          usual: text.HearthstoneBGLeague,
          title: textTitle.HearthstoneBGLeague,
        },
        HearthstoneCustom: {
          usual: text.HearthstoneCustom,
          title: textTitle.HearthstoneCustom,
        },
        WarzoneBattleRoyale: {
          usual: text.WarzoneBattleRoyale,
          title: textTitle.WarzoneBattleRoyale,
          metaTitle: 'CoD Warzone LFG - Call of Duty Warzone Team Finder | LF.Group',
        },
        WarzonePlunder: {
          usual: text.WarzonePlunder,
          title: textTitle.WarzonePlunder,
          metaTitle: 'CoD Warzone LFG - Call of Duty Warzone Team Finder | LF.Group',
        },
        WowTBCArena5v5: {
          usual: text.WowTBCArena5v5,
          title: textTitle.WowTBCArena5v5,
          metaTitle: 'WoW TBC Arena LFG - Arena Partner Finder | LF.Group',
        },
        WowTBCDungeon: {
          usual: text.WowTBCDungeon,
          title: textTitle.WowTBCDungeon,
          metaTitle: 'WoW TBC LFG - World of Warcraft TBC Team Finder | LF.Group',
        },
        HearthstoneLeagueSilvername: {
          usual: text.HearthstoneLeagueSilvername,
          title: textTitle.HearthstoneLeagueSilvername,
        },
        HearthstoneLeagueTricksterinoKsedden: {
          usual: text.HearthstoneLeagueTricksterinoKsedden,
          title: textTitle.HearthstoneLeagueTricksterinoKsedden,
        },
        HearthstoneLeagueFriendlyTournament: {
          usual: text.HearthstoneLeagueFriendlyTournament,
          title: textTitle.HearthstoneLeagueFriendlyTournament,
        },
        LostArkRaidsOnGuardians: {
          usual: text.LostArkRaidsOnGuardians,
          title: textTitle.LostArkRaidsOnGuardians,
          metaTitle: 'Lost Ark Guardian Raids LFG - Raids Team Finder | LF.Group',
        },
        LostArkRaidsOnTheLordsOfFetrania: {
          usual: text.LostArkRaidsOnTheLordsOfFetrania,
          title: textTitle.LostArkRaidsOnTheLordsOfFetrania,
          metaTitle:
            'Lost Ark Raids On The Lords Of Fetrania LFG - Raids On The Lords Of Fetrania Team Finder | LF.Group',
        },
        LostArkRaidsAbyss: {
          usual: text.LostArkRaidsAbyss,
          title: textTitle.LostArkRaidsAbyss,
          metaTitle:
            'Lost Ark Abyssal Raids LFG - Abyssal Raids Team Finder | LF.Group',
        },
        LostArkBossRush: {
          usual: text.LostArkBossRush,
          title: textTitle.LostArkBossRush,
          metaTitle: 'Lost Ark Boss Rush LFG - Boss Rush Team Finder | LF.Group',
        },
        LostArkTrialsOfTheAbyss: {
          usual: text.LostArkTrialsOfTheAbyss,
          title: textTitle.LostArkTrialsOfTheAbyss,
          metaTitle:
            'Lost Ark Trials Of The Abyss LFG - Trials Of The Abyss Team Finder | LF.Group',
        },
        LostArkGuardianHeroicRaids: {
          usual: text.LostArkGuardianHeroicRaids,
          title: textTitle.LostArkGuardianHeroicRaids,
          metaTitle:
            'Lost Ark Guardian Heroic Raids LFG - Guardian Heroic Raids Team Finder | LF.Group',
        },
        LostArkAbyssDungeons: {
          usual: text.LostArkAbyssDungeons,
          title: textTitle.LostArkAbyssDungeons,
          metaTitle:
            'Lost Ark Abyss Dungeons LFG - Abyss Dungeons Team Finder | LF.Group',
        },
        LostArkCube: {
          usual: text.LostArkCube,
          title: textTitle.LostArkCube,
          metaTitle: 'Lost Ark Cube LFG - Cube Team Finder | LF.Group',
        },
        LostArkTestPath: {
          usual: text.LostArkTestPath,
          title: textTitle.LostArkTestPath,
          metaTitle: 'Lost Ark Test Path LFG - Test Path Team Finder | LF.Group',
        },
        LostArkPlatinumFields: {
          usual: text.LostArkPlatinumFields,
          title: textTitle.LostArkPlatinumFields,
          metaTitle:
            'Lost Ark Platinum Fields LFG - Platinum Fields Team Finder | LF.Group',
        },
        LostArkOther: {
          usual: text.LostArkOther,
          title: textTitle.LostArkOther,
          metaTitle: 'Lost Ark Activities LFG - Find Team in Lost Ark | LF.Group',
        },
        LostArkLegionRaids: {
          usual: text.LostArkLegionRaids,
          title: textTitle.LostArkLegionRaids,
          metaTitle:
            'Lost Ark Legion Raids LFG - Legion Raids Team Finder | LF.Group',
        },
        LostArkArena: {
          usual: text.LostArkArena,
          title: textTitle.LostArkArena,
          metaTitle: 'Lost Ark Arena LFG - Arena Team Finder | LF.Group',
        },
        PUBGMobileTournament: {
          usual: text.PUBGMobileTournament,
          title: textTitle.PUBGMobileTournament,
          metaTitle: 'PUBG Mobile LFG - PUBG Mobile Team / Group Finder | LF.Group',
        },
        PUBGMobileScrims: {
          usual: text.PUBGMobileScrims,
          title: textTitle.PUBGMobileScrims,
        },
        PUBGMobileClassic: {
          usual: text.PUBGMobileClassic,
          title: textTitle.PUBGMobileClassic,
        },
        PUBGMobileArcade: {
          usual: text.PUBGMobileArcade,
          title: textTitle.PUBGMobileArcade,
        },
        PUBGMobileGiveaway: {
          usual: text.PUBGMobileGiveaway,
          title: textTitle.PUBGMobileGiveaway,
        },
        LolNormal: { usual: text.LolNormal, title: textTitle.LolNormal },
        LolAram: { usual: text.LolAram, title: textTitle.LolAram },
        LolDuo: { usual: text.LolDuo, title: textTitle.LolDuo },
        LolFlex: { usual: text.LolFlex, title: textTitle.LolFlex },
        LolClash: { usual: text.LolClash, title: textTitle.LolClash },
        LolFeatured: { usual: text.LolFeatured, title: textTitle.LolFeatured },
        LolCustom: { usual: text.LolCustom, title: textTitle.LolCustom },
        LolCustomAram: { usual: text.LolCustomAram, title: textTitle.LolCustomAram },
        LolNormalBlind: {
          usual: text.LolNormalBlind,
          title: text.LolNormalBlind,
        },
        PUBGMobileCustom: {
          usual: text.PUBGMobileCustom,
          title: textTitle.PUBGMobileCustom,
        },
        PUBGMobilePractice: {
          usual: text.PUBGMobilePractice,
          title: textTitle.PUBGMobilePractice,
        },
        CODMobilePractice: {
          usual: text.CODMobilePractice,
          title: textTitle.CODMobilePractice,
        },
        Custom: {
          usual: text.PUBGMobileCustom,
          title: textTitle.PUBGMobileCustom,
        },
        LostArkTravelingMerchants: {
          usual: '',
          title: '',
        },
        LostArkTravelingMerchantsUpcoming: {
          usual: '',
          title: '',
        },
      };

      return data[mode];
    },
    gameTitle: (_id: api.GameId) => {
      const id = _id as SupportedGameId;

      interface GameTitle {
        full: string;
        short: string;
      }
      const data: Record<SupportedGameId, GameTitle> = {
        Custom: { full: 'Custom Game', short: 'Custom' },
        Hearthstone: { full: 'Hearthstone', short: 'Hearthstone' },
        WorldOfWarcraft: { full: 'World of Warcraft', short: 'WoW' },
        GooseGooseDuck: { full: 'Goose Goose Duck', short: 'GGD' },
        CSGO: { full: 'Counter Strike: Global Offensive', short: 'CS:GO' },
        LeagueOfLegends: { full: 'League of Legends', short: 'LoL' },
        CODMobile: { full: 'Call of Duty: Mobile', short: 'CoD: Mobile' },
        PUBGMobile: { full: 'PUBG Mobile', short: 'PUBG Mobile' },
        LostArkEn: { full: 'Lost Ark', short: 'Lost Ark' },
        Standoff2: { full: 'Standoff 2', short: 'Standoff 2' },
        ShadowFight3: { full: 'Shadow Fight 3', short: 'Shadow Fight 3' },
        ShadowFight4Arena: {
          full: 'Shadow Fight 4 Arena',
          short: 'Shadow Fight 4',
        },
        DawnOfZombiesSurvival: {
          full: 'Dawn of Zombies: Survival',
          short: 'DoZ: Survival',
        },
        Crossfire: { full: 'Crossout Mobile - PvP Action', short: 'Crossout Mobile' },
        NewStateMobile: { full: 'New State Mobile', short: 'New State Mobile' },
        RiseOfEmpires: { full: 'Rise of Empires', short: 'Rise of Empires' },
        ModernStrikeOnlinePvPFPS: {
          full: 'Modern Strike Online: PvP FPS',
          short: 'MSO: PvP FPS',
        },
        RiseOfKingdomsLostCrusade: {
          full: 'Rise of Kingdoms: Lost Crusade',
          short: 'ROK: Lost Crusade',
        },
        IdleHeroes: { full: 'Idle Heroes', short: 'Idle Heroes' },
        LastDayOnEarthSurvival: {
          full: 'Last Day on Earth: Survival',
          short: 'LDoE: Survival',
        },
        MobileLegendsAdventure: {
          full: 'Mobile Legends: Adventure',
          short: 'ML: Adventure',
        },
        STALKER2HeartOfChornobyl: {
          full: 'STALKER 2: Heart of Chornobyl',
          short: 'STALKER 2: HoC',
        },
        Highrise: { full: 'Highrise', short: 'Highrise' },
        AFKArena: { full: 'AFK Arena', short: 'AFK Arena' },
      };

      const result = data[id];

      if (!result) {
        // console.warn('Unknown game in getTranslation/gameTitle:', id);
      }

      return result || { full: '', short: '' };
    },
    lfg: {
      tag: (tag: api.Maybe<LfgTag>) => {
        const text = locale.gameData.lfg.tags;

        if (!tag) {
          return 'Any';
        }

        const data: Record<LfgTag, string> = {
          LoadsFreeTime: text.LoadsFreeTime,
          LookingForFriends: text.LookingForFriends,
          LookingForTeam: text.LookingForTeam,
          Pro: text.Pro,
          TFT: text.TFT,
          FamilyPerson: text.FamilyPerson,
          Teenager: text.Teenager,
          Tournaments: text.Tournaments,
          Age2030: text.Age2030,
          Age3040: text.Age3040,
          Age40Plus: text.Age40Plus,
          NoVoice: text.NoVoice,
          TwoByTwo: text.twoByTwo,
          ThreeByThree: text.threeByThree,
          RBG: text.rbg,
          MythicPlus: text.mythicPlus,
          PvERaid: text.pveRaid,
          LookingForGuild: text.lookingForGuild,
          RaidLeader: text.raidLeader,
          Learning: text.learning,
          Casual: text.casual,
          TryHard: text.tryhard,
          AWC: text.awc,
          MDI: text.mdi,
          Voice: text.voice,
          Streamer: text.streamer,
          Mentor: text.mentor,
          Duo: text.duo,
          Flex: text.flex,
          Rift: text.rift,
          ARAM: text.aram,
          Normal: text.normal,
          Ranked: text.ranked,
          Clash: text.clash,
          TeamCaptain: text.teamCaptain,
          SemiPro: text.semiPro,
          PvP: text.pvp,
          PvE: text.pve,
          DE: text.de,
          UK: text.uk,
          US: text.us,
          ES: text.es,
          MX: text.mx,
          FR: text.fr,
          BR: text.br,
          EN: text.en,
          It: text.it,
          Pl: text.pl,
          Swe: text.swe,
          Tr: text.tr,
          LookingForStaticGroup: text.lookingForStaticGroup,
          Argos: text.argos,
          Valtan: text.valtan,
          Brelshaza: text.brelshaza,
          Vykas: text.vykas,
          KakulSaydon: text.KakulSaydon,
          OrehaWell: text.orehasWell,
          SemiHardcore: text.semiHardcore,
          Hardcore: text.hardcore,
          Coach: text.Coach,
          Female: text.Female,
          Male: text.Male,
          LookingForFemale: text.LookingForFemale,
          LookingForMale: text.LookingForMale,
          Coaching: text.coaching,
          Fun: text.fun,
          NightGames: text.nightGames,
          CasualTournaments: text.casualTournaments,
          TwentyThirtyYears: text.twentyThirtyYears,
          ThirtyFourtyYears: text.thirtyFourtyYears,
          FourtyPlusYears: text.fourtyPlusYears,
          Hellmode: text.hellmode,
          GvG: text.gvg,
          AbyssDungeons: text.abyssDungeons,
          LookingForRaid: text.lookingForRaid,
          LookingForCodriver: text.lookingForCodriver,
          LookingForClan: text.lookingForClan,
          LookingForGirl: text.lookingForGirl,
          LookingForMan: text.lookingForMan,
          ModdedServers: text.moddedServers,
          VanillaServers: text.vanillaServers,
          SkilledShooter: text.skilledShooter,
          Builder: text.builder,
          Girl: text.girl,
          Man: text.man,
          FullSupport: text.fullSupport,
          SemiSupport: text.semiSupport,
          Offlane: text.offlane,
          Mid: text.mid,
          Carry: text.carry,
          Smurf: text.smurf,
          Turbo: text.turbo,
          Lurker: text.lurker,
          Sniper: text.sniper,
          Support: text.support,
          Faceit: text.faceit,
          NonBinary: text.nonBinary,
          FreeAgent: text.freeAgent,
          TDM: text.tdm,
          CustomGames: text.customGames,
          Captain: text.captain,
          IGL: text.igl,
          Manager: text.manager,
          Newbie: text.newbie,
          MorningPrimeTime: text.morningPrimeTime,
          DaytimePrimeTime: text.daytimePrimeTime,
          EveningPrimeTime: text.eveningPrimeTime,
          Raid: text.raid,
          Arena: text.arena,
          Fps30Plus: text.fps30Plus,
          Fps60Plus: text.fps60Plus,
          Fps90Plus: text.fps90Plus,
          LookingForPlayers: text.lookingForPlayers,
          Age1114: text.age1114,
          Age1519: text.age1519,
          Age2024: text.age2024,
          Age2530: text.age2530,
          Age30Plus: text.ge30Plus,
        };

        return data[tag];
      },
    },
    group: {
      slotRole: (role: api.Maybe<api.SlotRole>) => {
        const text = locale.gameData.slotRole;

        if (!role || role === api.SlotRole.Unset) {
          return text.anyTitle;
        }

        const data: Record<api.SlotRole, string> = {
          Unset: text.Unset,
          WowTank: text.WowTank,
          WowHeal: text.WowHeal,
          WowDamage: text.WowDamage,
          CSGOSupport: text.CsgoSupport,
          CSGOAWPer: text.CsgoawPer,
          CSGOLurker: text.CsgoLurker,
          CSGOEntryFragger: text.CsgoEntryFragger,
          CSGOLeader: text.CsgoLeader,
          LostArkDamage: text.LostArkDamage,
          LostArkSupport: text.LostArkSupport,
          CustomMaybe: 'Maybe',
          CustomNo: 'No',
          CustomYes: 'Yes',
          LolSolo: text.LolSolo,
          LolJungle: text.LolJungle,
          LolMid: text.LolMid,
          LolDuo: text.LolDuo,
          LolSupport: text.LolSupport,
          Dota2SafeLane: '',
          Dota2MidLane: '',
          Dota2OffLane: '',
          Dota2SoftSupport: '',
          Dota2HardSupport: '',
        };

        return data[role];
      },
    },
    pubgMobile: {
      region: (region: api.Maybe<string>): string => {
        const text = locale.gameData.pubg.region;

        if (!region) {
          return text.any;
        }

        const data = pubg.REGIONS.reduce<Record<string, string>>((acc, value) => {
          return { ...acc, [value]: (text as Record<string, string>)[value] };
        }, {});

        return data[region] || text.any;
      },
    },
    codMobile: {
      region: (region: api.Maybe<string>): string => {
        const text = locale.gameData.cod.region;

        if (!region) {
          return text.any;
        }

        const data = cod.REGIONS.reduce<Record<string, string>>((acc, value) => {
          return { ...acc, [value]: (text as Record<string, string>)[value] };
        }, {});

        return data[region] || text.any;
      },
    },
    ggd: {
      region: (region: api.Maybe<string>): string => {
        const text = locale.gameData.custom.region;

        if (!region) {
          return text.any;
        }

        const data = custom.REGIONS.reduce<Record<string, string>>((acc, value) => {
          return { ...acc, [value]: (text as Record<string, string>)[value] };
        }, {});

        return data[region] || text.any;
      },
    },
    custom: {
      region: (region: api.Maybe<string>): string => {
        const text = locale.gameData.custom.region;

        if (!region) {
          return text.any;
        }

        const data = custom.REGIONS.reduce<Record<string, string>>((acc, value) => {
          return { ...acc, [value]: (text as Record<string, string>)[value] };
        }, {});

        return data[region] || text.any;
      },
    },
    steam: {
      region: (region: api.Maybe<string>): string => {
        const text = locale.gameData.steam.region;

        if (!region) {
          return text.any;
        }

        const data = [...steam.REGIONS, ...steam.CSGO_REGIONS].reduce<
          Record<string, string>
        >((acc, value) => {
          return { ...acc, [value]: (text as Record<string, string>)[value] };
        }, {});

        return data[region] || text.any;
      },
    },
    wow: {
      role: (role: api.WowRole) => {
        const text = locale.gameData.wow.role;

        const data: Record<api.WowRole, string> = {
          Damage: text.damage,
          Healer: text.heal,
          Tank: text.tank,
        };

        return data[role];
      },
      raid: (raid: api.Maybe<api.WowRaid>) => {
        const text = locale.gameData.wow.raid;
        if (!raid) {
          return text.anyTitle;
        }

        const data: Record<api.WowRaid, string> = {
          SanctumOfDomination: text.SanctumOfDomination,
          SepulcherOfTheFirstOnes: text.SepulcherOfTheFirstOnes,
        };

        return data[raid];
      },
      raidDifficulty: (raidDifficulty: api.Maybe<api.WowRaidDifficulty>) => {
        const text = locale.gameData.wow.raidDifficulty;

        if (!raidDifficulty) {
          return text.anyTitle;
        }

        const data: Record<api.WowRaidDifficulty, string> = {
          Normal: text.Normal,
          Heroic: text.Heroic,
          Mythic: text.Mythic,
        };

        return data[raidDifficulty];
      },
      dungeon: (dungeon: api.Maybe<api.WowDungeon>) => {
        const text = locale.gameData.wow.dungeons;

        if (!dungeon) {
          return text.anyTitle;
        }

        const data: Record<api.WowDungeon, string> = {
          HallsOfAtonement: text.HallsOfAtonement,
          SanguineDepths: text.SanguineDepths,
          Plaguefall: text.Plaguefall,
          TheNecroticWake: text.TheNecroticWake,
          DeOtherSide: text.DeOtherSide,
          MistsOfTirnaScithe: text.MistsOfTirnaScithe,
          SpiresOfAscension: text.SpiresOfAscension,
          TheaterOfPain: text.TheaterOfPain,
          TazaveshSoleahsGambit: text.TazaveshSoleahsGambit,
          TazaveshStreetsOfWonder: text.TazaveshStreetsOfWonder,
          TBCHellfireRamparts: text.TBCHellfireRamparts,
          TBCTheBloodFurnace: text.TBCTheBloodFurnace,
          TBCTheShatteredHalls: text.TBCTheShatteredHalls,
          TBCTheSlavePens: text.TBCTheSlavePens,
          TBCTheUnderbog: text.TBCTheUnderbog,
          TBCTheSteamvault: text.TBCTheSteamvault,
          TBCOldHillsbradFoothills: text.TBCOldHillsbradFoothills,
          TBCTheBlack: text.TBCTheBlack,
          TBCMorass: text.TBCMorass,
          TBCTheMechanar: text.TBCTheMechanar,
          TBCTheBotanica: text.TBCTheBotanica,
          TBCTheArcatraz: text.TBCTheArcatraz,
          TBCManaTombs: text.TBCManaTombs,
          TBCAuchenaiCrypts: text.TBCAuchenaiCrypts,
          TBCSethekkHalls: text.TBCSethekkHalls,
          TBCShadowLabyrinth: text.TBCShadowLabyrinth,
          OperationMechagonJunkyard: text.OperationMechagonJunkyard,
          OperationMechagonWorkshop: text.OperationMechagonWorkshop,
          ReturnToKarazhanLower: text.ReturnToKarazhanLower,
          ReturnToKarazhanUpper: text.ReturnToKarazhanUpper,
          GrimrailDepot: text.GrimrailDepot,
          IronDocks: text.IronDocks,
        };

        return data[dungeon];
      },
      faction: (faction: api.Maybe<api.WowFaction>) => {
        const text = locale.gameData.wow.faction;

        if (!faction) {
          return text.any;
        }

        const data: Record<api.WowFaction, string> = {
          Alliance: text.alliance,
          Horde: text.horde,
        };

        return data[faction];
      },
      region: (region: api.BattlenetRegion) => {
        const text = locale.gameData.wow.region;

        const data: Record<api.BattlenetRegion, string> = {
          US: text.US,
          Europe: text.Europe,
          APAC: text.APAC,
        };

        return data[region];
      },
      wowRegion: (region: api.Maybe<api.WowRegion>, withAny = true) => {
        const text = locale.gameData.wow.wowRegion;
        if (!region) {
          return withAny ? text.anyTitle : text.title;
        }

        const data: Record<api.WowRegion, string> = {
          US: text.US,
          Europe: text.Europe,
          China: text.China,
          Korea: text.Korea,
          Taiwan: text.Taiwan,
        };

        return data[region];
      },
      race: (race: api.WowRace) => {
        const text = locale.gameData.wow.race;

        const data: Record<api.WowRace, string> = {
          human: text.human,
          bloodElf: text.bloodElf,
          draenei: text.draenei,
          dwarf: text.dwarf,
          gnome: text.gnome,
          goblin: text.goblin,
          nightElf: text.nightElf,
          orc: text.orc,
          pandaren: text.pandaren,
          tauren: text.tauren,
          troll: text.troll,
          undead: text.undead,
          worgen: text.worgen,
          //non playable
          broken: '',
          darkIronDwarf: '',
          zandalariTroll: '',
          voidElf: '',
          vrykul: '',
          vulpera: '',
          tuskarr: '',
          skeleton: '',
          taunka: '',
          nightborne: '',
          highmountainTauren: '',
          iceTroll: '',
          kulTiran: '',
          lightforgedDraenei: '',
          magharOrc: '',
          mechagnome: '',
          naga: '',
          felOrc: '',
          forestTroll: '',
          gilnean: '',
        };

        return data[race];
      },
      class: (wowClass: api.Maybe<api.WowClass>) => {
        const text = locale.gameData.wow.class;

        if (!wowClass) {
          return text.unknown;
        }

        const data: Record<api.WowClass, string> = {
          Paladin: text.Paladin,
          Warlock: text.Warlock,
          DeathKnight: text.DeathKnight,
          DemonHunter: text.DemonHunter,
          Hunter: text.Hunter,
          Mage: text.Mage,
          Monk: text.Monk,
          Priest: text.Priest,
          Rogue: text.Rogue,
          Shaman: text.Shaman,
          Warrior: text.Warrior,
          Druid: text.Druid,
        };

        return data[wowClass];
      },
      specialization: (spec: api.Maybe<api.WowSpecialization>) => {
        const text = locale.gameData.wow.specialization;
        if (!spec) {
          return text.unknown;
        }

        const data: Record<api.WowSpecialization, string> = {
          DeathKnightBlood: text.DeathKnightBlood,
          DeathKnightFrost: text.DeathKnightFrost,
          DeathKnightUnholy: text.DeathKnightUnholy,
          DemonHunterHavoc: text.DemonHunterHavoc,
          DemonHunterVengeance: text.DemonHunterVengeance,
          DruidBalance: text.DruidBalance,
          DruidFeral: text.DruidFeral,
          DruidGuardian: text.DruidGuardian,
          DruidRestoration: text.DruidRestoration,
          HunterBeastMastery: text.HunterBeastMastery,
          HunterMarksmanship: text.HunterMarksmanship,
          HunterSurvival: text.HunterSurvival,
          MageArcane: text.MageArcane,
          MageFire: text.MageFire,
          MageFrost: text.MageFrost,
          MonkBrewmaster: text.MonkBrewmaster,
          MonkMistweaver: text.MonkMistweaver,
          MonkWindwalker: text.MonkWindwalker,
          PaladinHoly: text.PaladinHoly,
          PaladinProtection: text.PaladinProtection,
          PaladinRetribution: text.PaladinRetribution,
          PriestDiscipline: text.PriestDiscipline,
          PriestHoly: text.PriestHoly,
          PriestShadow: text.PriestShadow,
          RogueAssassination: text.RogueAssassination,
          RogueOutlaw: text.RogueOutlaw,
          RogueSubtlety: text.RogueSubtlety,
          ShamanElemental: text.ShamanElemental,
          ShamanEnhancement: text.ShamanEnhancement,
          ShamanRestoration: text.ShamanRestoration,
          WarlockAffliction: text.WarlockAffliction,
          WarlockDemonology: text.WarlockDemonology,
          WarlockDestruction: text.WarlockDestruction,
          WarriorArms: text.WarriorArms,
          WarriorFury: text.WarriorFury,
          WarriorProtection: text.WarriorProtection,
          WotlkDeathKnightLichborne: 'Lichborne',
          WotlkDeathKnightRuneblade: 'Runeblade',
          WotlkDruidWarden: 'Warden',
          WotlkPaladinJusticar: 'Justicar',
          WotlkWarriorChampion: 'Champion',
          WotlkWarriorGladiator: 'Gladiator',
        };

        return data[spec];
      },
    },

    hearthstone: {
      region: (region: api.Maybe<api.HearthstoneRegion>) => {
        const text = locale.gameData.hearthstone.region;

        if (!region) {
          return locale.gameData.hearthstone.region.any;
        }

        const data: Record<api.HearthstoneRegion, string> = {
          Unset: text.Unset,
          Asia: text.Asia,
          China: text.China,
          Europe: text.Europe,
          US: text.Americas,
        };

        return data[region];
      },
    },
    warzone: {
      region: (region: api.Maybe<api.WarzoneRegion>) => {
        const text = locale.gameData.warzone.region;

        if (!region) {
          return text.any;
        }

        const data: Record<api.WarzoneRegion, string> = {
          USAndCanada: text.USAndCanada,
          LatinAmerica: text.LatinAmerica,
          EU: text.EU,
          Germany: text.Germany,
          Russia: text.Russia,
          MiddleEast: text.MiddleEast,
          Africa: text.Africa,
          Australia: text.Australia,
          Asia: text.Asia,
          Pacific: text.Pacific,
          Japan: text.Japan,
        };

        return data[region];
      },
    },
    lol: {
      region: (region: api.Maybe<api.LolRegion>, short = false) => {
        const text = locale.gameData.lol.region;

        if (!region) {
          return text.any;
        }

        const data: Record<api.LolRegion, string> = {
          Unset: text.any,
          Brazil1: text.Brazil1,
          EuNorth1: text.EuNorth1,
          EuWest1: text.EuWest1,
          LatinAmerica1: text.LatinAmerica1,
          LatinAmerica2: text.LatinAmerica2,
          NorthAmerica1: text.NorthAmerica1,
          Korea: text.Korea,
          Japan1: text.Japan1,
          Oceania1: text.Oceania1,
          Russia: text.Russia,
          Turkey1: text.Turkey1,
        };

        if (short) {
          return data[region].split(' ')[0];
        }

        return data[region];
      },
      category: (category: api.Maybe<api.LolClass>) => {
        const text = locale.gameData.lol.category;

        if (!category) {
          return text.any;
        }

        const data: Record<api.LolClass, string> = {
          Controller: text.Controller,
          Fighter: text.Fighter,
          Mage: text.Mage,
          Marksman: text.Marksman,
          Slayer: text.Slayer,
          Specialist: text.Specialist,
          Tank: text.Tank,
        };

        return data[category];
      },
    },
    wildRift: {
      region: (region: api.Maybe<api.RiotRegion>) => {
        const text = locale.gameData.wildRift.region;

        if (!region) {
          return locale.gameData.hearthstone.region.any;
        }

        const data: Record<api.RiotRegion, string> = {
          Europe: text.Europe,
          Americas: text.Americas,
          Asia: text.Asia,
        };

        return data[region];
      },
      // ignoreDivision returns `Iron` instead of `Iron IV`
      rank: (rank: api.Maybe<api.LolRank>, ignoreDivision = false) => {
        const text = locale.gameData.wildRift.rank;

        if (!rank) {
          return text.any;
        }

        const data: Record<api.LolRank, string> = {
          Iron4: text.Iron4,
          Iron3: text.Iron3,
          Iron2: text.Iron2,
          Iron1: text.Iron1,
          Bronze4: text.Bronze4,
          Bronze3: text.Bronze3,
          Bronze2: text.Bronze2,
          Bronze1: text.Bronze1,
          Silver4: text.Silver4,
          Silver3: text.Silver3,
          Silver2: text.Silver2,
          Silver1: text.Silver1,
          Gold4: text.Gold4,
          Gold3: text.Gold3,
          Gold2: text.Gold2,
          Gold1: text.Gold1,
          Platinum4: text.Platinum4,
          Platinum3: text.Platinum3,
          Platinum2: text.Platinum2,
          Platinum1: text.Platinum1,
          Emerald4: text.Emerald4,
          Emerald3: text.Emerald3,
          Emerald2: text.Emerald2,
          Emerald1: text.Emerald1,
          Diamond4: text.Diamond4,
          Diamond3: text.Diamond3,
          Diamond2: text.Diamond2,
          Diamond1: text.Diamond1,
          Master: text.Master,
          Grandmaster: text.Grandmaster,
          Challenger: text.Challenger,
          None: text.None,
        };

        if (ignoreDivision && rank !== api.LolRank.None) {
          return data[rank].split(' ')[0];
        }

        return data[rank];
      },
    },
    pubg: {
      rank: (rank: api.Maybe<PubgMobileRank>) => {
        const text = locale.gameData.pubg.rank;

        if (!rank) {
          return text.any;
        }

        const data: Record<PubgMobileRank, string> = {
          NoRank: text.NoRank,
          Bronze: text.Bronze,
          Silver: text.Silver,
          Gold: text.Gold,
          Platinum: text.Platinum,
          Diamond: text.Diamond,
          Crown: text.Crown,
          As: text.Ace,
          AsMaster: text.AceMaster,
          AsDominator: text.AceDominator,
          Conqueror: text.Conqueror,
        };

        return data[rank];
      },
    },
    cod: {
      rank: (rank: api.Maybe<CodMobileRank>) => {
        const text = locale.gameData.cod.rank;

        if (!rank) {
          return text.any;
        }

        const data: Record<CodMobileRank, string> = {
          NoRank: text.NoRank,
          Rookie: text.Rookie,
          Veteran: text.Veteran,
          Elite: text.Elite,
          Pro: text.Pro,
          Master: text.Master,
          Grandmaster: text.Grandmaster,
          Legendary: text.Legendary,
        };

        return data[rank];
      },
      region: (region: api.Maybe<string>) => {
        const text = locale.gameData.cod.region;

        if (!region) {
          return locale.gameData.cod.region.any;
        }

        const data: Record<string, string> = {
          Unset: locale.gameData.cod.region.any,
          NA: text.NA,
          SA: text.SA,
          SEA: text.SEA,
          SAM: text.SAM,
          EU: text.EU,
          OC: text.OC,
          AF: text.AF,
        };

        return data[region];
      },
    },
    standoff2: {
      rank: (rank: api.Maybe<Standoff2Rank>) => {
        const text = locale.gameData.standoff2.rank;

        if (!rank) {
          return text.any;
        }

        const data: Record<Standoff2Rank, string> = {
          NoRank: text.NoRank,
          Bronze: text.Bronze,
          Silver: text.Silver,
          Gold: text.Gold,
          Phoenix: text.Phoenix,
          Ranger: text.Ranger,
          Champion: text.Champion,
          Master: text.Master,
          Elite: text.Elite,
          Legendary: text.Legendary,
        };

        return data[rank];
      },
      region: (region: api.Maybe<string>) => {
        const text = locale.gameData.standoff2.region;

        if (!region) {
          return locale.gameData.standoff2.region.any;
        }

        const data: Record<string, string> = {
          Unset: locale.gameData.standoff2.region.any,
          RU: text.RU,
        };

        return data[region];
      },
    },
    csgo: {
      role: (role: api.CsgoRole) => {
        const text = locale.gameData.csgo.role;

        const data: Record<api.CsgoRole, string> = {
          Support: text.Support,
          AWPer: text.AWPer,
          Lurker: text.Lurker,
          EntryFragger: text.EntryFragger,
          IGL: text.IGL,
          Rifler: text.Rifler,
        };

        return data[role];
      },
      rank: (rank: api.Maybe<api.CsgoRank>) => {
        const text = locale.gameData.csgo.rank;

        if (!rank) {
          return text.any;
        }

        const data: Record<api.CsgoRank, string> = {
          Silver1: text.Silver1,
          Silver2: text.Silver2,
          Silver3: text.Silver3,
          Silver4: text.Silver4,
          SilverElite: text.SilverElite,
          SilverEliteMaster: text.SilverEliteMaster,
          GoldNova1: text.GoldNova1,
          GoldNova2: text.GoldNova2,
          GoldNova3: text.GoldNova3,
          GoldNovaMaster: text.GoldNovaMaster,
          MasterGuardian1: text.MasterGuardian1,
          MasterGuardian2: text.MasterGuardian2,
          MasterGuardianElite: text.MasterGuardianElite,
          DistinguishedMasterGuardian: text.DistinguishedMasterGuardian,
          LegendaryEagle: text.LegendaryEagle,
          LegendaryEagleMaster: text.LegendaryEagleMaster,
          SupremeMasterFirstClass: text.SupremeMasterFirstClass,
          GlobalElite: text.GlobalElite,
        };

        return data[rank];
      },
      region: (region: api.Maybe<api.CsgoRegion>) => {
        const text = locale.gameData.csgo.region;

        if (!region) {
          return locale.gameData.steam.region.any;
        }

        const data: Record<api.CsgoRegion, string> = {
          Unset: locale.gameData.steam.region.any,
          EuEast: text.EuEast,
          EuWest: text.EuWest,
          Eu: text.Eu,
          Asia: text.Asia,
          US: text.US,
        };

        return data[region];
      },
    },
    lostArk: {
      region: (region: api.Maybe<api.LostArkRegion>) => {
        const text = locale.gameData.lostArk.region;

        if (!region) {
          return text.any;
        }

        const data: Record<api.LostArkRegion, string> = {
          Unset: text.any,
          EUCentral: text.EUCentral,
          EUWest: 'EU West',
          USEast: text.USEast,
          USWest: text.USWest,
          SA: 'South America',
          RU: text.RU,
        };

        return data[region];
      },
      guardian: (guardian: api.Maybe<api.LostArkGuardians>) => {
        const text = locale.gameData.lostArk.guardian;

        if (!guardian) {
          return text.any;
        }

        const data: Record<api.LostArkGuardians, string> = {
          UrNil: text.Urnil,
          Lumerus: text.Lumerus,
          IcyLegoros: text.IcyLegoros,
          Vertus: text.Vertus,
          Chromanium: text.Chromanium,
          Nacrasena: text.Nacrasena,
          FlameFoxYoho: text.FlameFoxYoho,
          Tytalos: text.Tytalos,
          DarkLegoros: text.DarkLegoros,
          Helgaia: text.Helgaia,
          Calventus: text.Calventus,
          Achates: text.Achates,
          FrostHelgaia: text.FrostHelgaia,
          LavaChromanium: text.LavaChromanium,
          Levanos: text.Levanos,
          Alberhastic: text.Alberhastic,
          ArmoredNacrasena: text.ArmoredNacrasena,
          Igrexion: text.Igrexion,
          NightFoxYoho: text.NightFoxYoho,
          Velganos: text.Velganos,
          // excess guardians
          Descaluda: text.Descaluda,
          Kangelranium: '',
          Kaeligos: '',
          Other: '',
          Kungelanium: '',
          Hanumatan: '',
        };

        return data[guardian];
      },
      abyssDungeonsAbyss: (abyss: api.Maybe<api.LostArkDungeon1>) => {
        const text = locale.gameData.lostArk.abyssDungeonsAbyss;

        if (!abyss) {
          return text.any;
        }
        const data: Record<api.LostArkDungeon1, string> = {
          RuinsOfElberia: text.RuinsOfElberia,
          AbodeOfDreams: text.AbodeOfDreams,
          CrucibleOfSouls: text.CrucibleOfSouls,
          GreatGate: text.GreatGate,
          HeartOfOreya: text.HeartOfOreya,
        };

        return data[abyss];
      },
      abyssDungeonsDungeon: (abyssDungeon: api.Maybe<api.LostArkDungeon2>) => {
        const text = locale.gameData.lostArk.abyssDungeonsDungeon;

        if (!abyssDungeon) {
          return text.any;
        }
        const data: Record<api.LostArkDungeon2, string> = {
          DemonBeastCanyon: text.DemonBeastCanyon,
          NecromancersOrigin: text.NecromancersOrigin,
          HallOfTheTwistedWarlord: text.HallOfTheTwistedWarlord,
          HildebrandtPalace: text.HildebrandtPalace,
          RoadOfLament: text.RoadOfLament,
          ForgottenForge: text.ForgottenForge,
          SeaOfIndolence: text.SeaOfIndolence,
          TranquilKarkosa: text.TranquilKarkosa,
          AlaricsSanctuary: text.AlaricsSanctuary,
          AirasOculus: text.AirasOculus,
          OrehaPreveza: text.OrehaPreveza,
          CliffOfTheNecromancer: '',
          CitadelOfIllusions: '',
          AvrelsudPalace: '',
          RoadOfSorrow: '',
          ForgeOfFallenPride: '',
          OblivionSea: '',
          ThePerilousAbyss: '',
          UnderwaterSanctuary: '',
          MaddenedForest: '',
          RottingGlade: '',
          TheAbodeOfDeath: '',
        };

        return data[abyssDungeon];
      },
      cubeDungeon: (cubeDungeon: api.Maybe<api.LostArkCube>) => {
        const text = locale.gameData.lostArk.cubeDungeon;

        if (!cubeDungeon) {
          return text.any;
        }
        const data: Record<api.LostArkCube, string> = {
          Cube: text.Cube,
          EliteCube: text.EliteCube,
          DimensionalCube: text.DimensionalCube,
          CubeOfTheAncients: '',
          GuardiansCube: '',
        };

        return data[cubeDungeon];
      },
      bossRush: (bossRush: api.Maybe<BossRush>) => {
        const text = locale.gameData.lostArk.bossRush;

        if (!bossRush) {
          return text.any;
        }
        const data: Record<BossRush, string> = {
          [BossRush.HallOfSilence]: text.HallOfSilence,
          [BossRush.HallOfTheSun]: text.HallOfTheSun,
        };

        return data[bossRush];
      },
      platinumFieldsField: (platinumFieldsField: api.Maybe<PlatinumFieldsField>) => {
        const text = locale.gameData.lostArk.platinumFieldsField;
        if (!platinumFieldsField) {
          return text.any;
        }

        const data: Record<PlatinumFieldsField, string> = {
          [PlatinumFieldsField.NahunsDomain]: text.NahunsDomain,
          [PlatinumFieldsField.OldYudianCanal]: text.OldYudianCanal,
        };

        return data[platinumFieldsField];
      },
      otherActivities: (otherActivities: api.Maybe<OtherActivities>) => {
        const text = locale.gameData.lostArk.otherActivities;

        if (!otherActivities) {
          return text.any;
        }

        const data: Record<OtherActivities, string> = {
          [OtherActivities.Misc]: text.Misc,
          [OtherActivities.SecretMap]: text.SecretMap,
        };

        return data[otherActivities];
      },
      arena: (arena: api.Maybe<api.LostArkArena>) => {
        const text = locale.gameData.lostArk.arena;

        if (!arena) {
          return text.any;
        }

        const data: Record<api.LostArkArena, string> = {
          NormalMatch: text.NormalMatch,
          CoopBattle: text.CoopBattle,
          CustomGame: text.CustomGame,
        };

        return data[arena];
      },
      arenaMode: (arenaMode: api.Maybe<api.LostArkArenaMode>) => {
        const text = locale.gameData.lostArk.arenaMode;

        if (!arenaMode) {
          return text.any;
        }

        const data: Record<api.LostArkArenaMode, string> = {
          TeamDeathmatch: text.TeamDeathmatch,
          TeamElimination: text.TeamElimination,
        };

        return data[arenaMode];
      },
      raid: (raid: api.Maybe<api.LostArkRaid>) => {
        const text = locale.gameData.lostArk.raid;

        if (!raid) {
          return text.any;
        }

        const data: Record<api.LostArkRaid, string> = {
          Argos: text.Argos,
          Valtan: text.Valtan,
          Vykas: text.Vykas,
          Brelshaza: text.Brelshaza,
          KakulSaydon: text.KakulSaydon,
          AngerVoldan: '',
          Avrelsud: '',
          CrazyBoothKuSatot: '',
          GardensofTemptationBelakis: '',
        };

        return data[raid];
      },
      phase: (phase: api.Maybe<api.LostArkPhase>) => {
        const text = locale.gameData.lostArk.phase;

        if (!phase) {
          return text.any;
        }

        const data: Record<api.LostArkPhase, string> = {
          Phase1: text.Phase1,
          Phase2: text.Phase2,
          Phase3: text.Phase3,
          Phase12: '',
          Phase34: '',
          Phase56: '',
        };

        return data[phase];
      },

      class: (lostArkClass: api.Maybe<api.LostArkArenaClass>) => {
        const text = locale.gameData.lostArk.class;

        if (!lostArkClass) {
          return text.any;
        }

        const data: Record<api.LostArkArenaClass, string> = {
          Berserker: text.Berserker,
          Paladin: text.Paladin,
          Gunlancer: text.Gunlancer,
          Striker: text.Striker,
          Wardancer: text.Wardancer,
          Scrapper: text.Scrapper,
          Soulfist: text.Soulfist,
          Glaivier: text.Glaivier,
          Gunslinger: text.Gunslinger,
          Machinist: text.Machinist,
          Destroyer: text.Destroyer,
          Artillerist: text.Artillerist,
          Deadeye: text.Deadeye,
          Sharpshooter: text.Sharpshooter,
          Bard: text.Bard,
          Arcanist: text.Arcanist,
          Summoner: text.Summoner,
          Sorceress: text.Sorceress,
          Shadowhunter: text.Shadowhunter,
          Deathblade: text.Deathblade,
          Reaper: text.Reaper,
        };

        return data[lostArkClass];
      },
      difficulty: (difficulty: api.Maybe<api.LostArkDifficulty>) => {
        const text = locale.gameData.lostArk.difficulty;

        if (!difficulty) {
          return text.any;
        }

        const data: Record<api.LostArkDifficulty, string> = {
          Hard: text.Hard,
          Normal: text.Normal,
          Hell: text.Hell,
          Dejavu: text.Dejavu,
          Default: '',
          Heroic: '',
          Mythic: '',
          Repetition: '',
        };

        return data[difficulty];
      },
    },
    weekDays: {
      full: (weekDay: number) => {
        const text = locale.calendar.weekDays;

        const data: Record<number, string> = {
          0: text.sunday.full,
          1: text.monday.full,
          2: text.tuesday.full,
          3: text.wednesday.full,
          4: text.thursday.full,
          5: text.friday.full,
          6: text.saturday.full,
        };

        return data[weekDay];
      },
      short: (weekDay: number) => {
        const text = locale.calendar.weekDays;

        const data: Record<number, string> = {
          0: text.sunday.short,
          1: text.monday.short,
          2: text.tuesday.short,
          3: text.wednesday.short,
          4: text.thursday.short,
          5: text.friday.short,
          6: text.saturday.short,
        };

        return data[weekDay];
      },
      extraShort: (weekDay: number) => {
        const text = locale.calendar.weekDays;

        const data: Record<number, string> = {
          0: text.sunday.extraShort,
          1: text.monday.extraShort,
          2: text.tuesday.extraShort,
          3: text.wednesday.extraShort,
          4: text.thursday.extraShort,
          5: text.friday.extraShort,
          6: text.saturday.extraShort,
        };

        return data[weekDay];
      },
    },
  };
}
